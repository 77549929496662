<template>
  <div>
    <v-card class="rounded-lg shadow my-5" :loading="isLoading" :disabled="isLoading">
    <v-card-title>
        <div class="f-15">
          Statistiques détaillées

          <v-icon @click="getEPaymentsStatistics">mdi-reload</v-icon>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon color="gifty" @click="show = !show">
          <v-icon v-if="!show">mdi-plus-circle</v-icon>
          <v-icon v-else>mdi-minus-circle</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider v-if="show"></v-divider>

      <v-card-text v-if="show">
        <v-row justify="center">
          <v-col cols="12" lg="6">
            <v-simple-table class="rounded-lg table-border">
              <template v-slot:default>
                <thead>
                <tr>
                  <th>Service</th>
                  <th>Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,i) in statistics" :key="i">
                  <td>{{ item.name }}</td>
                  <td class="font-weight-medium gifty--text">{{ CurrencyFormatting(item.total_amount) }} DZD</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      show: false,
      isLoading: false,
      statistics: {},
    }
  },
  methods: {
    getEPaymentsStatistics() {
      this.isLoading = true
      HTTP.get('/v1/e-payments/statistics').then((res) => {
        this.statistics = res.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getEPaymentsStatistics()
  }
}
</script>

<style scoped>

</style>