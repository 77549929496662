<template>
    <div>
        <v-dialog v-if="campaign"
                  v-model="dialog"
                  persistent
                  scrollable
                  width="780"
        >
            <v-card>

                <v-card-title>
                    {{ campaign.title }}
                    <v-spacer/>
                    <v-icon @click="close">mdi-close</v-icon>
                </v-card-title>

                <v-divider/>

                <v-card-text class="pa-4">

                    <v-row align-content="center"
                           class="my-5"
                           justify="center">
                        <v-col>
                            <v-card outlined>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="8">
                                            Montant envoyé
                                            <span class="d-block font-weight-medium">
                              {{ CurrencyFormatting(campaign.amount) }} DZD
                      </span>
                                        </v-col>
                                        <v-col class="text-right" cols="4">
                                            <v-avatar color="gifty" size="40">
                                                <v-icon dark>mdi-currency-usd</v-icon>
                                            </v-avatar>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card outlined>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="8">
                                            Coût total
                                            <span class="d-block font-weight-medium">
                             {{ CurrencyFormatting(campaign.messages_sum_amount) }} DZD
                      </span>
                                        </v-col>
                                        <v-col class="text-right" cols="4">
                                            <v-avatar color="gifty" size="40">
                                                <v-icon dark>mdi-currency-usd</v-icon>
                                            </v-avatar>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card outlined>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="8">
                                            Coût des SMS
                                            <span class="d-block font-weight-medium">
                          {{ CurrencyFormatting(campaign.sms_cost) }} DZD
                         </span>
                                        </v-col>
                                        <v-col class="text-right" cols="4">
                                            <v-avatar color="primary" size="40">
                                                <v-icon dark>mdi-currency-usd</v-icon>
                                            </v-avatar>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-textarea v-if="campaign.sms"
                                v-model="campaign.sms"
                                class="rounded"
                                disabled
                                filled
                                flat
                                label="SMS"
                                prepend-inner-icon="mdi-cellphone-message"
                                readonly
                                rounded
                                rows="2"/>

                    <v-textarea v-if="campaign.message"
                                v-model="campaign.message"
                                class="rounded"
                                disabled
                                filled
                                flat
                                label="Message"
                                prepend-inner-icon="mdi-message-outline"
                                readonly
                                rounded
                                rows="2"/>


                    <v-simple-table style="border: 1px solid #eaeaea">
                        <template v-slot:default>
                            <tbody>

                            <tr>
                                <td class="font-weight-medium gifty--text">
                                    Titre
                                </td>
                                <td>
                                    {{ campaign.title }}
                                </td>
                            </tr>

                            <tr>
                                <td class="font-weight-medium gifty--text">
                                    Type des SMS
                                </td>
                                <td class="text-uppercase">
                                    {{
                                    campaign.type === 'gifty' ? 'Persons qui non pas de compte GIFTY' : 'Tous le monde'
                                    }}
                                </td>
                            </tr>

                            <tr class="grey lighten-5">
                                <td class="font-weight-medium gifty--text">
                                    Image
                                </td>
                                <td>
                                    <v-avatar class="my-2">
                                        <v-img :src="fileUrl + campaign.image" class="grey lighten-4"></v-img>
                                    </v-avatar>
                                </td>
                            </tr>

                            <tr>
                                <td class="font-weight-medium gifty--text">
                                    Base de données
                                </td>
                                <td>
                                    {{ campaign.database ? campaign.database.name : '-' }}
                                </td>
                            </tr>

                            <tr class="grey lighten-5">
                                <td class="font-weight-medium gifty--text">
                                    Vidéo youtube
                                </td>
                                <td>
                                    <v-btn :disabled="!campaign.youtube_link"
                                           color="red"
                                           icon
                                           @click="$refs.youtubeDialog.open(campaign.youtube_link)">
                                        <v-icon>mdi-youtube</v-icon>
                                    </v-btn>
                                </td>
                            </tr>

                            <tr>
                                <td class="font-weight-medium gifty--text">
                                    Planification
                                </td>
                                <td>
                                    <v-icon :style="{marginTop:'-3px'}"
                                            color="gifty"
                                            small>mdi-clock-outline
                                    </v-icon>
                                    {{ campaign.scheduled_datetime }}
                                </td>
                            </tr>

                            <tr class="grey lighten-5">
                                <td class="font-weight-medium gifty--text">
                                    Date création
                                </td>
                                <td>
                                    <v-icon :style="{marginTop:'-3px'}"
                                            color="gifty"
                                            small>mdi-clock-outline
                                    </v-icon>
                                    {{ campaign.created_at }}
                                </td>
                            </tr>

                            </tbody>
                        </template>
                    </v-simple-table>


                </v-card-text>

            </v-card>
        </v-dialog>


    </div>
</template>

<script>


export default {
    data() {
        return {
            campaign: null,
            dialog: false,
            fileUrl: process.env.VUE_APP_FILE_URL
        }
    },
    methods: {
        open(item) {
            this.campaign = item
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
    },
}
</script>
<style scoped>
.v-data-table {
    border-radius: 5px !important;
}
</style>