<template>
  <div>
    <v-dialog
            v-model="dialog"
            persistent
            scrollable
            width="400"
    >

      <v-card>

        <v-card-title>
          Filtrer par
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-radio-group v-model="status" hide-details label="Statut de paiement">
            <v-radio label="Réussie" value="success"></v-radio>
            <v-radio label="En attente" value="pending"></v-radio>
            <v-radio label="Échec" value="error"></v-radio>
          </v-radio-group>

          <v-dialog
                  ref="dialog"
                  v-model="modal"
                  persistent
                  width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                      v-model="dateRangeText"
                      append-icon="mdi-calendar-outline"
                      clearable
                      label="Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="dates = []"
              ></v-text-field>
            </template>

            <v-date-picker v-model="dates" range scrollable>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="modal = false">
                Fermer
              </v-btn>
              <v-btn :disabled="dates.length < 2"
                     color="primary"
                     text
                     @click="modal = false">
                ok
              </v-btn>
            </v-date-picker>
          </v-dialog>

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-btn :disabled="(dates.length < 2 )  && (!status)"
                 block
                 color="primary"
                 depressed type="submit"
                 @click="handleFilter">
            <v-icon left>mdi-magnify</v-icon>
            Rechercher
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>
  </div>
</template>

<script>

export default {
    data() {
        return {
            modal: false,
            dialog: false,
            status: null,
            dates: [],
        }
    },
    methods: {
        open() {
            this.dates = []
            this.status = null
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
        handleFilter() {
            let data = {
                dates: this.dates,
                status: this.status,
            }
            this.$emit('filter', data)
            this.close()
        },
    },
    computed: {
        dateRangeText: {
            get() {
                return this.dates.join(' ~ ')
            },
            set(dates) {
                return dates
            }
        }
    },
}
</script>

